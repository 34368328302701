import { GroupMemberRole } from "../constants/enums";
import {
  Building,
  BuildingUnitDto,
  InviteTenantDto,
  OwnedBuildingDto,
  SyncBuildingUnitDto,
  UpdateTenantDto,
} from "../definitions/model/Building";
import { GroupMembersDto } from "../definitions/model/Group";
import User from "../definitions/model/User";
import ApiService from "./api.service";

const BuildingService = {
  getBuilding: async (buildingId: string) => {
    const url = `/api/buildings/${buildingId}`;
    const result = await ApiService.get<Building>(url);
    return result;
  },
  getBuildingPhotos: async (houseNumber: number, street: string, postalCode: number) => {
    const serviceUrl = process.env.REACT_APP_IMAGE_SERVICE_URL;
    const width = 600;
    const height = 400;

    const url = `${serviceUrl}/GetAllPhotosByAddress?houseNumber=${houseNumber}&street=${street}&postCode=${postalCode}&width=${width}&height=${height}`;

    const response = await ApiService.get<{
      East: string;
      West: string;
      North: string;
      South: string;
    }>(url);
    const photos = Object.entries(response).map(([_, v]) => "data:image/gif;base64," + v);
    return photos;
  },
  moveInToBuilding: async (buildingId: string) => {
    const url = "/api/users/movein/building";
    const moveinRequest = {
      BuildingId: buildingId,
    };
    const data = await ApiService.post<{ User: User; Building: Building }>(url, moveinRequest);
    return data;
  },
  updateUserCurrentBuilding: async (id: string) => {
    const url = `/api/users/updatecurrentbuilding/${id}`;
    const result = await ApiService.put<{ Building: Building; User: User }>(url, {
      NewPropertyId: id,
    });
    return result;
  },
  moveOutBuilding: async (buildingId: string) => {
    const url = `/api/users/moveOut/${buildingId}/building`;
    const result = await ApiService.delete<User>(url);
    return result;
  },
  getOwnedBuildings: async (signal?: AbortSignal) => {
    const url = "/api/buildings/owned";
    const result = await ApiService.get<OwnedBuildingDto[]>(url, null, signal);
    return result;
  },
  updateBuildingManagerInfo: async (buildingId: string, email: string) => {
    const url = `/api/buildings/manager`;
    const result = await ApiService.put<Building>(url, { buildingId, managerEmail: email });
    return result;
  },
  getBuildingMembers: async (buildingId: string) => {
    const url = `/api/buildings/${buildingId}/members`;
    const result = await ApiService.get<GroupMembersDto>(url);
    return result;
  },
  sendSuggestion: async (unitId: string, suggestion: string) => {
    const url = `/api/buildings/suggestion`;
    await ApiService.post(url, { unitId, suggestion });
  },
  sendMessageToTenants: async (buildingId: string, recipients: string[], message: string) => {
    const url = `/api/buildings/${buildingId}/tenants/message`;
    const data = {
      BuildingId: buildingId,
      Recipients: recipients,
      Message: message,
    };
    await ApiService.post(url, data);
  },
  changeMemberRole: async (buildingId: string, userId: string, role: GroupMemberRole) => {
    const url = `/api/buildings/${buildingId}/members/${userId}/role`;
    return await ApiService.put<GroupMembersDto>(url, { Role: role });
  },
  inviteMember: async (buildingId: string, name: string, email: string, role: GroupMemberRole) => {
    const url = `/api/buildings/${buildingId}/members`;
    const data = {
      Name: name,
      Email: email,
      Role: role,
      Source: buildingId,
    };

    return await ApiService.post<GroupMembersDto>(url, data);
  },
  acceptInviteMember: async (buildingId: string, token: string) => {
    const url = `/api/buildings/${buildingId}/members/${token}`;
    return await ApiService.put(url, null);
  },
  removeMember: async (buildingId: string, memberId: string, token: string) => {
    const url = `/api/buildings/${buildingId}/members/${memberId}?token=${token}`;

    return await ApiService.delete(url, null);
  },
  inviteTenant: async (buildingId: string, data: InviteTenantDto[]) => {
    const url = `/api/buildings/${buildingId}/tenants`;
    return await ApiService.post<BuildingUnitDto>(url, data);
  },
  updateTenant: async (buildingId: string, tenantId: string, data: UpdateTenantDto) => {
    const url = `/api/buildings/${buildingId}/tenants/${tenantId}`;
    return await ApiService.put<BuildingUnitDto>(url, data);
  },
  removePendingTenant: async (buildingId: string, unitId: string, memberId: string, token: string) => {
    const url = `/api/buildings/${buildingId}/tenants/${memberId}?unitId=${unitId}&token=${token}`;

    return await ApiService.delete(url, null);
  },
  updatePropertyNumber: async (buildingId: string, data: SyncBuildingUnitDto[]) => {
    const url = `/api/buildings/${buildingId}/units/number`;
    return await ApiService.put<BuildingUnitDto>(url, data);
  },
};

export default BuildingService;
